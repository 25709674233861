import { BOTTOM_BAR_HEIGHT } from "components/BottomBar/constants";
import { getIsSupportModalOpen } from "ee/selectors/aiAgentSelectors";
import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

const Wrapper = styled.div`
  position: absolute;
  bottom: ${BOTTOM_BAR_HEIGHT}px;
  right: 0px;
  width: 20%;
  min-width: 70ch;
  padding-right: var(--ads-v2-spaces-6);
  padding-bottom: var(--ads-v2-spaces-6);
  z-index: 999;
  height: 80vh;
  background: none;
  iframe {
    box-shadow: var(--ads-v2-shadow-popovers);
    height: 100%;
    width: 100%;
    border: 1px solid var(--ads-v2-color-border);
    border-radius: var(--ads-v2-spaces-4);
  }
`;

export function AIAgentSupportModal() {
  const isSupportModalOpen = useSelector(getIsSupportModalOpen);

  if (!isSupportModalOpen) return null;

  return (
    <Wrapper>
      <iframe src="https://agents.app.appsmith.com/app/support-agent/page1-67f4a1a1fdc63a2a4b0eacd1?embed=true&environment=production" />
    </Wrapper>
  );
}
