import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@appsmith/ads";
import {
  hideWarningPublishAnvilApplication,
  saveSchemaPostWarningInit,
} from "ee/actions/generateSchemaActions";
import {
  getIsSavingSchemaPostWarning,
  getIsWarningPublishAnvilApplicationOpen,
} from "ee/selectors/actionSchemaSelectors";
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

export function ShowWarningPublishAnvilApplicationModal() {
  const dispatch = useDispatch();
  const isOpen = useSelector(getIsWarningPublishAnvilApplicationOpen);
  const isSavingSchemaPostWarning = useSelector(getIsSavingSchemaPostWarning);

  const handleClose = useCallback(() => {
    dispatch(hideWarningPublishAnvilApplication());
  }, [dispatch]);

  const handleSaveAndDeploy = useCallback(() => {
    dispatch(saveSchemaPostWarningInit());
  }, [dispatch]);

  return (
    <Modal
      onOpenChange={(open) => {
        if (!open) {
          handleClose();
        }
      }}
      open={isOpen}
    >
      <ModalContent style={{ width: "460px" }}>
        <ModalHeader isCloseButtonVisible={false}>
          Unsaved changes detected
        </ModalHeader>
        <ModalBody>
          You&apos;ve made changes to one or more queries or JS functions, that
          haven&apos;t been saved. Save all changes before deploying.
          <ModalFooter>
            <Button kind="secondary" onClick={handleClose} size="md">
              Cancel
            </Button>
            <Button
              isLoading={isSavingSchemaPostWarning}
              onClick={handleSaveAndDeploy}
              size="md"
            >
              Save all and deploy
            </Button>
          </ModalFooter>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
