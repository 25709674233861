import history from "utils/history";
import { queryEditorIdURL } from "ee/RouteBuilder";
import { useDispatch, useSelector } from "react-redux";
import { getDefaultBasePageId } from "sagas/selectors";
import { setIdeEditorViewMode } from "actions/ideActions";
import { getActions } from "ee/selectors/entitiesSelector";
import { EditorViewMode } from "IDE/Interfaces/EditorTypes";
import { getIsAiAgentFlowEnabled } from "ee/selectors/aiAgentSelectors";
import { resetCurrentApplicationIdForCreateNewApp } from "actions/onboardingActions";
import { getCurrentApplicationIdForCreateNewApp } from "ce/selectors/applicationSelectors";
import {
  openCarbonModal,
  toggleFCIntegrations,
} from "ee/actions/aiAgentActions";

/**
 * For ai agent instance, when the user signups, we get BUILDER_PATH url,
 * but we want to redirect to QUERIES_EDITOR_ID_PATH
 * This hooks handles this by grabbing the actions from the
 * application and redirecting to the correct url
 */
export const useAgentsRedirect = () => {
  const dispatch = useDispatch();
  const applicationIdForCreateNewApp = useSelector(
    getCurrentApplicationIdForCreateNewApp,
  );
  const defaultPageId = useSelector(getDefaultBasePageId);
  const actions = useSelector(getActions);
  const isAiAgentFlowEnabled = useSelector(getIsAiAgentFlowEnabled);
  const action = actions[0];

  if (!isAiAgentFlowEnabled) return null;

  // When the user signups, we set the applicationIdForCreateNewApp,
  // Since we need to redirection flow only for the new user flow,
  // we only move forward if the applicationIdForCreateNewApp is set
  if (!applicationIdForCreateNewApp) return null;

  if (!action) return null;

  const queryURL = queryEditorIdURL({
    basePageId: defaultPageId,
    baseQueryId: action.config.baseId,
  });

  if (!queryURL) return null;

  history.replace(queryURL);

  dispatch(setIdeEditorViewMode(EditorViewMode.SplitScreen));
  dispatch(resetCurrentApplicationIdForCreateNewApp());
  dispatch(toggleFCIntegrations({ isEnabled: true }));
  dispatch(openCarbonModal({ shouldOpen: true }));
};
