import React from "react";
import { Button } from "@appsmith/ads";
import { toggleAISupportModal } from "ee/actions/aiAgentActions";
import {
  getIsSupportModalOpen,
  getIsAiAgentFlowEnabled,
} from "ee/selectors/aiAgentSelectors";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

const ToggleButton = styled(Button)`
  &[data-selected="true"] {
    background-color: var(--ads-v2-colors-control-field-checked-bg) !important;
    border-radius: 0px !important;
    & svg {
      color: var(--ads-v2-color-bg) !important;
    }
    & span {
      color: var(--ads-v2-color-bg) !important;
    }
    &&:hover {
      --button-color-bg: var(
        --ads-v2-colors-control-field-checked-bg
      ) !important;
      --button-color-fg: var(--ads-v2-color-bg) !important;
    }
  }
`;

export const AIAgentSupportTrigger = () => {
  const dispatch = useDispatch();
  const isAiAgentFlowEnabled = useSelector(getIsAiAgentFlowEnabled);
  const isSupportModalOpen = useSelector(getIsSupportModalOpen);

  if (!isAiAgentFlowEnabled) return null;

  return (
    <ToggleButton
      data-selected={isSupportModalOpen}
      kind="tertiary"
      onClick={() => dispatch(toggleAISupportModal())}
      size="md"
      startIcon="ai-chat"
    >
      Ask Appsmith
    </ToggleButton>
  );
};
